import { styled } from 'styled-components'

export const BlockWrapper = styled.div`
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin: auto;
	font-size: 42px;
	text-align: center;
`
